import jump from "jump.js";

window.Jump = function(target) {
  jump(target, {
    offset: -80
  });
  document.getElementById("nav-icon").classList.toggle("animate-icon");
  document.getElementById("overlay").classList.toggle("overlay-open");
};

window.goTop = function(target) {
  jump(target);
};

window.overlay = function() {
  var element = document.getElementById("nav-icon");
  element.classList.toggle("animate-icon");
  document.getElementById("overlay").classList.toggle("overlay-open");
};

window.toggleOverlay = function() {
  var x = document.getElementById("overlay");
  document.getElementById("nav-icon").classList.toggle("animate-icon");
};

window.onscroll = function() {
  toggleHeaderClassName();
};

function toggleHeaderClassName() {
  var greaterThanFifty =
    document.body.scrollTop > 100 || document.documentElement.scrollTop > 100;
  var header = document.getElementById("header");
  var headerBgClassName = "header-background";

  greaterThanFifty
    ? header.classList.add(headerBgClassName)
    : header.classList.remove(headerBgClassName);
}

window.onresize = function() {
  myFunction();
};

function myFunction() {
  var w = window.innerWidth;
  if (w > 720) {
    document.getElementById("overlay").style.heigth = "100%";
  } else {
    document.getElementById("nav-icon").classList.remove("animate-icon");
    document.getElementById("overlay").classList.remove("overlay-open");
  }
}

console.log("Vision Studio - 2020");
